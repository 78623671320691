<template>
  <v-form v-model="valid">
    <generic-form
      ref="carDetailsForm"
      :fields-config="carDetailsFieldConfig"
      :data="data"
      :loading="loading"
      hide-error
      :btns="{ show: [] }"
    />
    <v-tabs
      v-model="activeTab"
      background-color="primary lighten-1"
      dark
      class="my-2"
    >
      <v-tab
        v-for="(item, index) in tabs"
        :key="index"
      >
        {{ item.name }}
      </v-tab>
    </v-tabs>

    <v-tabs-items
      v-model="activeTab"
      class="mb-3 px-5 pt-2"
    >
      <v-progress-linear
        v-if="tabs.length < 1"
        indeterminate
        color="primary"
      />
      <v-tab-item
        v-for="(item, index) in tabs"
        :key="index"
        eager
      >
        <generic-form
          :ref="`airportSpecificFieldsForm${index}`"
          :fields-config="airportSpecificFieldsConfig"
          :data="getAirportSpecificFieldsData(item)"
          :loading="loading"
          hide-error
          :btns="{ show: [] }"
        />
      </v-tab-item>
    </v-tabs-items>

    <v-row v-if="(btns.show || []).length">
      <v-col>
        <custom-btn
          v-if="cancelBtn.shouldDisplay"
          id="generic-form-cancel-btn"
          class="mr-2"
          :disabled="loading"
          @click="onCancel"
        >
          {{ cancelBtn.label }}
        </custom-btn>
        <custom-btn
          id="generic-form-submit-btn"
          class="float-right"
          color="primary"
          :loading="loading"
          @click="onSubmit"
        >
          {{ submitBtn.label }}
        </custom-btn>
      </v-col>
    </v-row>
    <v-row v-if="errorMessage">
      <v-col
        cols="12"
        class="d-flex justify-center py-0"
      >
        <div class="error--text">
          <strong>{{ errorMessage }}</strong>
        </div>
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
import CustomBtn from '@/shared/components/CustomBtn.vue';
import GenericForm from './GenericForm.vue';
import { cloneDeep } from 'lodash';
import { mapActions } from 'vuex';
import { getAcuratePayload } from '@/mixins/CrudMixin';

export default {
    name: 'TabularGenericForm',
    components: {
        GenericForm,
        CustomBtn,
    },
    props: {
        fieldsConfig: {
            type: Array,
            default: () => [],
        },
        data: {
            type: Object,
            default: () => {},
        },
        loading: {
            type: Boolean,
            default: false,
        },
        btns: {
            type: Object,
            default: () => {
                return {
                    show: ['all'],
                    cancelLabel: 'Cancel',
                    submitLabel: 'Submit',
                };
            },
        },
    },
    data() {
        return {
            valid: false,
            payload: {},
            previousPayload: {},
            activeTab: 0,
            tabs: [],
            errorMessage: null,
        };
    },
    computed: {
        carDetailsFieldConfig() {
            return this.fieldsConfig.filter((field) => field.standAlone);
        },
        airportSpecificFieldsConfig() {
            return this.fieldsConfig.filter((field) => !field.standAlone);
        },
        isFormDirty() {
            let isDirty = this.$refs.carDetailsForm.isFormDirty;
            isDirty =
        isDirty ||
        this.tabs.some(
            (i, index) =>
                this.$refs[`airportSpecificFieldsForm${index}`][0].isFormDirty
        );
            return isDirty;
        },
        cancelBtn() {
            const btn = {};
            if (['all', 'cancel'].find((btn) => this.btns?.show?.includes(btn))) {
                btn.label = this.btns.cancelLabel || 'Cancel';
                btn.shouldDisplay = true;
            }
            return btn;
        },
        submitBtn() {
            const btn = {};
            if (['all', 'submit'].find((btn) => this.btns?.show?.includes(btn))) {
                btn.label = this.btns.submitLabel || 'Submit';
                btn.shouldDisplay = true;
            }
            return btn;
        },
    },
    watch: {
        data(newValue) {
            if (newValue) {
                this.createPayload();
            }
        },
        payload: {
            handler(val) {
                this.fieldsConfig.forEach((field) => {
                    if (field.optionalIf) {
                        field.required = !val[field.optionalIf];
                    }
                });
                setImmediate(() => {
                    this.$emit('onChange', {
                        payload: val,
                        isDirty: this.isFormDirty,
                        isValid: this.valid,
                    });
                });
            },
            deep: true,
        },
    },
    created() {
        this.createPayload();
        this.fetch();
    },
    methods: {
        ...mapActions(['fetchAirports']),
        createPayload() {
            this.payload = JSON.parse(JSON.stringify(this.data || {}));
            this.previousPayload = cloneDeep(this.data || {});
        },
        validate() {
            let isValid = this.$refs.carDetailsForm.validate();
            if (isValid) {
                isValid = this.tabs.every((_, index) =>
                    this.$refs[`airportSpecificFieldsForm${index}`][0].validate()
                );
            }
            this.errorMessage = isValid
                ? null
                : 'Please complete all required fields for each airport before submitting the form!';
            return isValid;
        },
        getAirportSpecificFieldsData(airport) {
            if (this.data) {
                if (!airport.id)
                    return this.data.formulas.filter((f) => !f.airportId)[0];
                return (
                    this.data.formulas.find(
                        (formula) => formula.airportId === airport.id
                    ) || {}
                );
            }
            return airport;
        },
        onSubmit() {
            if (this.validate()) {
                if (this.isFormDirty) {
                    this.payload = this.$refs.carDetailsForm.payload;
                    this.payload.formulas = [];
                    this.tabs.forEach((airport, index) => {
                        let obj =
              this.$refs[`airportSpecificFieldsForm${index}`][0].payload;
                        obj = getAcuratePayload(obj);
                        this.payload.formulas.push({
                            airportId: airport.id,
                            airportName: airport.name,
                            toAdd: obj.toAdd,
                            toMultiply: obj.toMultiply,
                            minimumRate: obj.minimumRate,
                        });
                    });

                    this.$emit('onSubmit', this.payload);
                }
                this.errorMessage = this.isFormDirty
                    ? null
                    : 'No changes are detected to update!';
            }
        },
        async fetch() {
            this.tabs = await this.fetchAirports();
            this.tabs.push({
                id: null,
                name: 'Point To Point',
                shortName: 'PTP',
            });
        },
        onCancel() {
            this.activeTab = 0;
            this.$emit('onCancel');
            this.errorMessage = null;
        },
    },
};
</script>

<style lang="scss">
.v-slide-group__prev--disabled {
  display: none;
}

@media (min-width: 300px) {
  .v-slide-group__prev {
    display: none !important;
  }
}

.v-tabs {
  .v-item-group {
    border-radius: 6px;
  }
}
</style>
