import { cloneDeep, isNull } from 'lodash';
import { singular } from 'pluralize';
import Service from '@/services/index';

export function getAcuratePayload(payload) {
    const newPayload = cloneDeep(payload);
    Object.keys(newPayload).forEach((key) => {
        if (
            !['boolean'].includes(typeof newPayload[key]) &&
          !isNaN(+newPayload[key]) &&
          typeof +newPayload[key] === 'number'
        ) {
            if (newPayload[key] !== '') {
                newPayload[key] = +newPayload[key];
            }
        }
    });
    return newPayload;
}

export default {
    data() {
        return {
            service: {},
        };
    },
    computed: {
        messageKey() {
            const field = this.headers.find((h) => h.shouldDisplayedInMessage) || {};
            return (this.selectedItem || {})[field.value] || 'Record';
        },
        fieldsConfig() {
            return this.headers
                .filter((h) => h.fieldConfig)
                .map((h) => h.fieldConfig);
        },
        singularTitle() {
            return singular(this.title) || '';
        },
    },
    methods: {
        createServiceInstance() {
            this.service = new Service(`${this.url}`);
        },
        getAcuratePayload,
    },
};
