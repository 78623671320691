<template>
  <v-data-table
    :id="id"
    v-bind="$attrs"
    ref="customDataTable"
    mobile-breakpoint="100"
    :headers="[
      ...computedHeaders,
      {
        text: '',
        value: 'settings',
        sortable: false,
        width: '20px',
      },
    ]"
    v-on="$listeners"
  >
    <template
      v-if="dataTableSetting"
      #header.settings="props"
    >
      <data-table-setting
        v-model="selectedHeaders"
        :headers="headersToHideInTable"
        :title="title"
      />
    </template>

    <template #item.$$default="{ item, header }">
      {{ getValue({ item, header }) }}
    </template>

    <template #item.$$percentage="{ item, header }">
      {{ getValue({ item, header, append: "%" }) }}
    </template>

    <template #item.$$boolean="{ item, header }">
      {{
        typeof header.getValue === "function"
          ? header.getValue({ item, header })
            ? "Yes"
            : "No"
          : "N/A"
      }}
    </template>

    <template #item.$$amount="{ item, header }">
      <amount-value :amount="getValue({ item, header, defaultValue: 0 })" />
    </template>

    <template #item.$$toggle="{ item, header }">
      <div class="is-active">
        <v-switch
          v-model="item[header.valueFrom]"
          color="primary"
          inset
          @change="(e) => $emit('on-toggle', e, item)"
        />
      </div>
    </template>
    <template #item.$$redirectedTo="{ item, header }">
      <a
        v-if="header.getPath(item).path"
        @click="redirectedToPath(header.getPath(item))"
      >
        {{ getValue({ item, header }) }}
      </a>
      <span v-else-if="header.getPath(item).value">
        {{ getValue({ item, header }) }}
      </span>
      <span v-else> N/A </span>
    </template>
    <!-- <template #item.isRoundTrip="{ item }">
        {{ item.childOf ? "Yes" : "No" }}
      </template> -->

    <template #item.$$chip="{ item, header }">
      <v-chip :color="getStatusColor(item[header.valueFrom])">
        {{ getValue({ item, header, operations: ["titleCase"] }) }}
      </v-chip>
    </template>

    <template #item.$$date="{ item, header }">
      {{
        getValue(
          { item, header, operations: ["formatDate"] },
          header.isDateOnly
        )
      }}
    </template>

    <template #item.$$convertCase="{ item, header }">
      <v-tooltip
        v-if="header.isTooltip"
        top
        color="black"
      >
        <template #activator="{ on, attrs }">
          <div
            v-bind="attrs"
            class="truncate"
            v-on="on"
          >
            {{ getValueForToolTip({ item, header }) }}
          </div>
        </template>
        <p>
          {{ getValueForToolTip({ item, header }) }}
        </p>
      </v-tooltip>
      <span v-else>
        {{ getValueForToolTip({ item, header }) }}
      </span>
    </template>
    <template #item.$$actions="{ item, header }">
      <custom-image-btn
        :id="`action-btn-${item.id}`"
        :ref="`imageButton_${item.id}`"
        :key="`${item.id}_image-btn`"
        hidden
        :item="item"
        :show-btn="header.showUploadField"
        @onUpload="$emit('on-upload-image')"
      />
      <v-menu
        transition="slide-y-transition"
        bottom
      >
        <template #activator="{ on, attrs }">
          <v-btn
            class="pa-0 ma-0"
            text
            v-bind="attrs"
            v-on="on"
          >
            <v-icon>mdi-dots-vertical</v-icon>
          </v-btn>
        </template>
        <v-list v-if="item.$$actions.length">
          <template v-for="(action, i) in item.$$actions">
            <v-list-item
              :id="`action-btn-${i}`"
              :key="i"
              block
              dense
              class="ma-0"
              :color="action.color"
              @click="
                action.name === ActionDefaults.viewImage.name
                  ? openImageComponent(item)
                  : $emit('action-performed', { item, action, header })
              "
            >
              <span class="px-2">
                <v-icon
                  v-if="action.icon"
                  left
                >
                  {{ action.icon }}
                </v-icon>
                {{ titleCase(action.title || action.name) }}
              </span>
            </v-list-item>
          </template>
        </v-list>
        <v-list v-else>
          <v-list-item> No Action Available </v-list-item>
        </v-list>
      </v-menu>
    </template>
    <template
      v-for="(index, name) in $scopedSlots"
      #[name]="data"
    >
      <slot
        :name="name"
        v-bind="data"
      />
    </template>
  </v-data-table>
</template>
<script>
import _ from 'lodash';
import CommonMixin from '@/mixins/CommonMixin';
import DataTableSetting from '@/shared/components/DataTableSetting.vue';
import CustomImageBtn from '@/shared/components/CustomImageBtn.vue';
import { ActionDefaults } from '@/enums';

export default {
    components: {
        DataTableSetting,
        CustomImageBtn,
    },
    mixins: [CommonMixin],
    props: {
        id: {
            type: String,
            required: true,
        },
        dataTableSetting: {
            type: Boolean,
            default: false,
        },
        title: {
            type: String,
            default: 'Record',
        },
        actions: {
            type: Array,
            default: () => [],
        },
    },
    data: () => ({
        selectedHeaders: [],
        ActionDefaults,
    }),
    computed: {
        computedHeaders() {
            return this.selectedHeaders.length
                ? this.$attrs.headers.filter(
                    (x) =>
                        !x.hideHeader &&
              (x.text === 'Actions' || this.selectedHeaders.includes(x.text))
                )
                : this.$attrs.headers.filter((x) => !x.hideHeader);
        },
        headersToHideInTable() {
            return this.$attrs.headers.filter(
                (h) => !h.hideHeader && h.text !== 'Actions'
            );
        },
    },

    methods: {
        formatString(value, convertFuncName) {
            return _[convertFuncName](value);
        },
        openImageComponent(item) {
            this.$refs[`imageButton_${item.id}`].$refs.imageBtn.$el.click();
        },
        redirectedToPath(item) {
            if (item.newTab) {
                return window.open(item.path);
            }
            return this.$router.push(item.path);
        },
        getValue(
            {
                item = {},
                header = {},
                append = '',
                preAppend = '',
                operations = [],
                defaultValue = 'N/A',
            } = {},
            ...args
        ) {
            let value = this.getNestedValueFromObject(item, header.valueFrom);

            if (operations.length) {
                value = operations.reduce((acc, operation) => {
                    if (this[operation]) {
                        acc = this[operation](acc, ...args);
                    } else if (_[operation]) {
                        acc = _[operation](acc, ...args);
                    }
                    return acc;
                }, value);
            }

            return value ? `${preAppend}${value}${append}` : defaultValue;
        },
        getValueForToolTip(obj = {}) {
            const value = this.getValue(obj);
            return obj?.header?.valueFrom
                ? this.formatString(value, obj?.header?.convertInto)
                : value;
        },
    },
};
</script>
