<template>
  <div v-if="surges.length > 0">
    <v-tooltip
      top
      color="black"
    >
      <template #activator="{ on, attrs }">
        <v-icon
          v-bind="attrs"
          color="warning"
          v-on="on"
        >
          mdi-alert
        </v-icon>
      </template>
      <ul class="pb-2">
        <li
          v-for="(item, i) in surges"
          :key="i"
        >
          {{ item.description }}
        </li>
      </ul>
      <p>
        Due to the above reasons additional
        <span
          v-if="surgePercentage > 0"
          class="error--text"
        >{{ surgePercentage }}%</span>
        <amount-value
          v-else
          class="error--text"
          :amount="surgeAmount"
        />
        will be added in your total bill.
      </p>
    </v-tooltip>
  </div>
</template>

<script>
import AmountValue from '@/shared/components/AmountValue.vue';

export default {
    name: 'SurgeWarnings',
    components: {
        AmountValue,
    },
    props: {
        surges: {
            type: Array,
            default: () => [],
        },
    },
    computed: {
        surgePercentage() {
            return this.surges.reduce((acc, surge) => {
                acc += +surge.percentage;
                return acc;
            }, 0);
        },
        surgeAmount() {
            return this.surges.reduce((acc, surge) => {
                acc += surge.amount;
                return acc;
            }, 0);
        },
    },
};
</script>
